<template>
  <div class="home">
    <div class="container">
      <div class="home-head d-flex justify-space-between pt-5">
        <img src="../assets/logo.png" alt="">
        <div>
          <router-link to="/wins">
            <v-btn
              text
              class="white--text"
            >
              Выигранные дела
            </v-btn>
          </router-link>
          <router-link to="/resheniya">
            <v-btn
              color="#fff"
              text
              @click="drawer = false"
            >
              Решения и постановления судов
            </v-btn>
          </router-link>
          <router-link to="/chance">
            <v-btn
              color="#fff"
              text
            >
              ДОСРОЧНЫЙ ВОЗВРАТ ПРАВ
            </v-btn>
          </router-link>
        </div>
      </div>
      <div class="wrap d-flex flex-column align-center">
        <div class="wrap-title text-sm-h4 text-h5 text-md-h3 text-lg-h3">
          Лишают водительских прав?<br>
          Законно возвращаем права, оспаривая решения суда!<br> Добиваемся успеха в 7 случаях из 10*
<!--          <div>-->
<!--            Лишают водительских прав?<br>-->
<!--            Вернём!<br>-->
<!--            Узнайте как вернуть-->
<!--            водительские права за 3 минуты!<br>-->
<!--            Пройдите тест из 7 вопросов-->
<!--          </div>-->
          <span class="mt-5 mt-lg-10 d-block text-sm-h5 text-h6 text-md-h4 text-lg-h4">Вернем права досрочно!</span>
        </div>
        <v-dialog
          v-model="dialog.test"
          fullscreen
          hide-overlay
          transition="dialog-bottom-transition"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              rounded
              color="yellow"
              class="black--text mt-10"
              style="font-size: 20px"
              width="250px"
              height="50px"
            >
              Начать тест
            </v-btn>
          </template>
          <v-card class="test-wrap">
            <div class="test-wrap-progress d-flex align-center py-5 py-sm-7 py-md-10 py-lg-12">
              <div class="test-progress">
                <span
                  :style="{
                    left: `${progressValue -2}%`
                  }"
                >
                  {{progressValue}}%
                </span>
                <v-progress-linear color="white" :value="progressValue"></v-progress-linear>
              </div>
              <v-btn
                icon
                text
                @click="dialog.test = false"
                color="white"
                class="ml-10"
                v-if="1===2"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </div>
            <quest
              v-if="isQuest === 1"
              :is-quest="isQuest"
              question="В какой стадии дело?"
              :buttons="[
                      'Вынесено постановление',
                      'В ожидании суда',
                      'Лишили прав',
                      'Повторное лишение прав'
                    ]"
              @result="resultPush"
            />
            <quest
              v-if="isQuest === 2"
              :is-quest="isQuest"
              question="Есть ли материалы дела"
              :buttons="[
                      'Да, есть',
                      'Нет'
                    ]"
              @result="resultPush"
            />
            <quest
              v-if="isQuest === 3"
              :is-quest="isQuest"
              question="За что лишили прав?"
              @result="resultPush"
            />
            <quest
              v-if="isQuest === 4"
              :is-quest="isQuest"
              question="Сколько времени прошло после лишения прав?"
              :buttons="[
                      'Менее года',
                      'Более года',
                      'Еще не лишен'
                    ]"
              @result="resultPush"
            />
<!--            <quest-->
<!--              v-if="isQuest === 5"-->
<!--              :is-quest="isQuest"-->
<!--              question="Город или населенный пункт:"-->
<!--              @result="city"-->
<!--            />-->
            <quest
              v-if="isQuest === 5"
              :is-quest="isQuest"
              :statusSend="statusSend"
              @result="send"
            />
          </v-card>
        </v-dialog>
        <v-dialog
          v-model="dialog.successSend"
          width="500"
        >

          <v-card>
            <v-card-title class="text-h5 mb-3 green lighten-2 white--text">
              Заявка отправлена <v-icon color="white" class="ml-3">mdi-check-all</v-icon>
            </v-card-title>

            <v-card-text>
              <p style="font-size: 15px">Спасибо! Заявка принята, ожидайте звонка</p>
              <p class="">Наши контакты:</p>
              <a href="tel:+78002013236">Телефон +7 (800) 201 32 36</a><br>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="green"
                text
                @click="dialog.successSend = false"
              >
                Ок
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import Quest from './quest'
import querystring from 'querystring'
export default {
  name: 'Home',
  components: { Quest },
  data: () => ({
    valid: false,
    statusSend: false,
    form: {
      phone: '',
      email: '',
      city: ''
    },
    nameRules: [
      v => !!v || 'Введите имя',
      v => (v && v.length <= 10) || 'Имя не должно быть длиннее 10 символов'
    ],
    phoneRules: [
      value => !!value || 'Обязательное поле',
      value => (Boolean(Number(value))) || 'Введите только цифры',
      value => (value && value.length >= 11) || 'Не менее 11 цифр',
      value => (value && value.length <= 11) || 'Не более 11 цифр'
    ],
    emailRules: [
      v => !!v || 'E-mail обязательное поле',
      v => /.+@.+/.test(v) || 'E-mail введен не правильно'
    ],
    dialog: {
      wins: false,
      test: false,
      ebsa: false,
      chance: false,
      successSend: false
    },
    progressValue: 0,
    isQuest: 1,
    result: []
  }),
  methods: {
    async send (form) {
      this.statusSend = true
      const formData = new FormData()
      formData.append('crm', '11')
      formData.append('pipe', '22')
      formData.append('contact[199]', form.phone)
      formData.append('contact[200]', form.email)
      formData.append('contact[204]', this.form.city)
      formData.append('lead[214]', 'otzyv.avtourist.com')
      formData.append('note', 'сайт: otzyv.avtourist.com' + '<br>' + this.result.join().replace(/[,]/g, '')) // удаление запятых из this.result

      const formToMail = {
        name: ' -',
        email: form.email,
        phone: form.phone,
        message: this.result.join().replace(/[,]/g, '')
      }
      await axios.post('/send.php', querystring.stringify(formToMail)).then(res => (res.status === 200 && console.log('success send to mail')))
      await axios.post('https://technik-crm.ru:5000/lead/import-lead', {
        name: '',
        email: form.email,
        phone: form.phone,
        additional: '',
        website: 'otzyv.avtourist.com',
        generate: 'otzyv.avtourist.com',
        city: this.form.city,
        note: 'сайт: otzyv.avtourist.com' + '<br>' + this.result.join().replace(/[,]/g, '')
      }).then(res => {
        this.dialog.test = false
        // this.dialog.successSend = true
        this.statusSend = false
        // eslint-disable-next-line
        gtag('event', 'conversion', {'send_to': 'AW-10829377594/UpneCMjwz4wDELrg7Kso'});
        this.$router.push({ name: 'Thanks' })
      })
      // await axios.post('https://urist.v-avtoservice.com/api/import-lead',
      //   formData,
      //   {
      //     headers: {
      //       'Content-Type': 'multipart/form-data'
      //     }
      //   }
      // )
    },
    resultPush (button) {
      this.result.push(button) // button это текст кнопки, на которую нажали. она и пушится в результат чтоб потом все это отправить
      this.isQuest++ // при ответе на вопрос (нажатие 1 из вариантов ответа) прибавляется цифра, так меняются quest
      this.progressValue += 21 // поднимаем число прогресса
    },
    city (city) {
      this.form.city = city
      this.isQuest++ // при ответе на вопрос (нажатие 1 из вариантов ответа) прибавляется цифра, так меняются quest
      this.progressValue += 30 // поднимаем число прогресса
    },
    backWins () {
      this.dialog.wins = false
    }
  },
  mounted () {
  }
}
</script>
<style lang="scss">
  .home {
    .container {
      min-height: 100vh;
      width: 100vw;
      min-width: 100vw;
      background: url("../assets/full-bg.jpg");
      background-size: cover;
    }
    .home-head {
      @media (max-width: 500px) {
        flex-wrap: wrap;
        img {
          width: 100%;
          margin-bottom: 20px;
        }
      }
    }
    .wrap {
      .wrap-title {
        text-align: center;
        font-weight: 700;
        font-size: 2.6875rem;
        line-height: 1.2;
        max-width: 60%;
        margin: 0 auto;
        margin-top: 20%;
        color: white;
        @media (max-width: 500px) {
          margin-top: 100px;
        }
        @media (min-width: 1200px) {
          margin-top: 90px;
        }
        @media (max-width: 1200px) {
          margin-top: 100px;
        }
      }
    }
  }
  .test-wrap {
    min-height: 100vh;
    background: url("../assets/full-bg.jpg");
    background-size: cover;
    .test-wrap-progress {
      display: flex;
      justify-content: center;
      .test-progress {
        display: flex;
        align-items: center;
        width: 200px;
        position: relative;
        span {
          color: #ffffff;
          position: absolute;
          right: -20px;
          bottom: 12px;
          overflow-wrap: normal;
          transition: all .3s ease;
          padding-bottom: 4px;
          width: 1px;
          &::before {
            content: '';
            line-height: 24px;
            display: block;
            white-space: nowrap;
            font-size: 12px;
            font-weight: 500;
            border-radius: 3px;
            padding: 0 3px;
            width: 100%;
            height: 20px;
            top: 0;
            position: absolute;
          }
          &::after {
            content: "";
            -webkit-transform: translate(-50%);
            transform: translate(-50%);
            position: absolute;
            left: calc(50% + 3px);
            right: auto;
            bottom: -3px;
            width: 0;
            height: 0;
            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
            border-top: 6px solid currentColor;
            transition: left .3s ease;
          }
        }
      }
    }
  }
</style>
